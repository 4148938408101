import moment from 'moment'
import 'moment-timezone'

$(function() {
  var startsAtInput = $("#stream_event_form_schedule_starts_at");
  var $durationInput = $("#stream_event_form_schedule_duration");
  var $startsAtPicker = $("#datetime_picker_stream_event_form_schedule_starts_at");
  var $endsAtInput = $("#stream_event_form_schedule_ends_at");
  var $endsAtPicker = $("#datetime_picker_stream_event_form_schedule_ends_at");
  var $frequencyInput = $('#stream_event_form_schedule_frequency');

  if (!$startsAtPicker.length || !$endsAtPicker.length) {
    return;
  }

  function updateWeekdayOption() {
    var time, option = 'Weekly';
    if (time = $startsAtPicker.val()) {
      option += ' on ' + moment(time, [$startsAtPicker.format]).format("dddd") + 's';
    }
    $('option:last', $frequencyInput).text(option);
  }

  function updateDuration() {
    var seconds = moment.duration(parseInt($durationInput.val()), 'seconds');
    $('#js-duration').html(seconds.asHours().toFixed(1) + " hour(s)");
  }

  $startsAtPicker.on('change', function(event) {
    var startsAt = moment($startsAtPicker.val(), [$startsAtPicker.format]);
    var duration = moment.duration(parseInt($durationInput.val()), 'seconds');
    var endsAt = startsAt.add(duration);

    $endsAtPicker.val(endsAt.format($endsAtPicker.format));
    $endsAtInput.val(endsAt.toISOString());
    updateWeekdayOption();
  });

  $endsAtPicker.on('change', function(event) {
    var startsAt = moment($startsAtPicker.val(), [$startsAtPicker.format]);
    var endsAt = moment($endsAtPicker.val(), [$endsAtPicker.format]);

    if (!$endsAtPicker.val().length) {
      $durationInput.val(0);
    } else if(startsAt > endsAt) {
      $endsAtPicker.val(startsAt.format($endsAtPicker.format));
    } else {
      $durationInput.val(endsAt.diff(startsAt)/1000);
    }
    updateDuration();
  });

  setTimeout(function() {

    $startsAtPicker.format = $startsAtPicker[0].config.format;
    $endsAtPicker.format   = $endsAtPicker[0].config.format;

    updateWeekdayOption();
    updateDuration();
  }, 10);

});
