import { Application } from "@hotwired/stimulus"
import "@hotwired/turbo-rails"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false

// Extend the Window interface to include Stimulus
declare global {
  interface Window {
    Stimulus: Application
  }
}

window.Stimulus = application

// Disable turbo drive globally. Until we remove selectize and dropzone, we need a full page refresh for the JS to work. We can enable turbo on a element basis with turbo-drive="true"
Turbo.session.drive = false

export { application }