import {Chart} from 'chart.js/auto'
import sub from 'date-fns/sub'

export function initChart() {
  var ctx = $("#downloads_chart");

  if (ctx.length > 0) {
    const min = sub(Date.now(), { years: 1 })
    const max = new Date()

    var downloadsChart = new Chart(ctx[0] as HTMLCanvasElement, {
      type: 'line',
      data: {
        datasets: ctx.data('datasets')
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          }
        }
      }
    });
  }
}