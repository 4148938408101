import '../application/streamEventsForm'
import '@hotwired/turbo-rails'
import '../../javascript/controllers'

import { initChart } from "../application/chart"
import { initPublishingRequests } from '../application/publishingRequests'

function init() {
  initChart()
  initPublishingRequests()
}

$(init)
