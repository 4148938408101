import { Controller } from "@hotwired/stimulus";

class MessagesController extends Controller<HTMLFormElement> {
  static targets = ["speakers", "audiences", "tags", "topic", "allFilter"];

  declare speakersTarget: HTMLSelectElement;
  declare audiencesTarget: HTMLSelectElement;
  declare tagsTarget: HTMLSelectElement;
  declare topicTarget: HTMLSelectElement;
  declare allFilterTarget: HTMLInputElement;

  connect() {
    this.initializeSelectInputs();
    this.initializeStatusFilters();
  }

  handleInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.type === "radio") {
      this.uncheckAllButtons();
      input.checked = true;
    }

    this.element.requestSubmit();
  }

  uncheckAllButtons() {
    this.element
      .querySelectorAll<HTMLInputElement>('input[type="radio"]')
      .forEach((radio) => {
        radio.checked = false;
      });
  }

  initializeStatusFilters() {
    // In the case where no status filters are check, make sure "all" is checked
    const checkedRadio = this.element.querySelector<HTMLInputElement>(
      'input[type="radio"]:checked'
    );
    if (!checkedRadio) {
      this.allFilterTarget.checked = true;
    }
  }

  initializeSelectInputs() {
    const url = new URL(window.location.href);

    for (const input of this.selectInputs) {
      const paramValue = `q[${input.dataset.messagesTarget}_id_eq]`;
      const value = url.searchParams.get(paramValue);

      // We're initializing selectize here so that we can control the event listeners
      $(input).selectize({
        hideSelected: true,
        items: value ? [value] : [],
        onChange: () => this.element.requestSubmit(),
      });
    }
  }

  get selectInputs() {
    return [
      this.speakersTarget,
      this.audiencesTarget,
      this.tagsTarget,
      this.topicTarget,
    ];
  }
}

export default MessagesController;
