import connectMenu, { MenuConnectorParams, MenuRenderState, MenuWidgetDescription } from 'instantsearch.js/es/connectors/menu/connectMenu';

type AlgoliaSelectizeMenuWidgetParams = MenuConnectorParams & {
  container: string
}

type AlgoliaSelectizeMenuWidget = (widgetParams: AlgoliaSelectizeMenuWidgetParams) => any

export const algoliaSelectizeMenuWidget: AlgoliaSelectizeMenuWidget = connectMenu(
  (renderOptions, isFirstRender) => {
    const {
      refine,
      canRefine,
      widgetParams,
      items,
    } = renderOptions;

    const {
      container,
      attribute,
    } = widgetParams
    const id = `SelectizeMenu-${attribute}`

    const isRendered = !!document.querySelector(`#${id}`)

    // Only render once so we don't have to figure out updating the items list.  This should be fine for our
    // use case.  Anything more complex requires react.
    if (!isRendered && items.length > 0) {
      const containerNode = document.querySelector(container)
      containerNode.innerHTML = ''

      const innerHTML = $(`
        <select id="${id}">
          <option value="">All</option>
          ${items.map(item => `<option value="${item.value}">${item.label}</option>`)}
        </select>
      `)
      innerHTML.appendTo(containerNode)

      innerHTML.selectize({
        hideSelected: false,
        plugins: ['remove_button']
      });
      innerHTML.on('change', function() {
        refine($(this).val())
      })
    }
  }
)
