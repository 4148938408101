import connectMenu, { MenuConnectorParams, MenuRenderState, MenuWidgetDescription } from 'instantsearch.js/es/connectors/menu/connectMenu';

type AlgoliaDatePickerWidgetParams = MenuConnectorParams & {
  container: string
}

type AlgoliaDatePickerWidget = (widgetParams: AlgoliaDatePickerWidgetParams) => any

export const algoliaDatePickerWidget: AlgoliaDatePickerWidget = connectMenu(
  (renderOptions, isFirstRender) => {
    const {
      refine,
      canRefine,
      widgetParams,
    } = renderOptions;

    const {
      container,
      attribute,
    } = widgetParams
    const id = `datepicker-${attribute}`

    if (isFirstRender) {
      const containerNode = document.querySelector(container)
      containerNode.innerHTML = ''

      const innerHTML = $(`<input id="${id}" class="date" type="text" />`)
      innerHTML.appendTo(containerNode)

      innerHTML.datepicker({
        timepicker: false,
        format: 'YYYY-MM-DD',
      });
      innerHTML.on('change', function() {
        const val = $(this).val()
        const formatted = val ? new Date(val).toISOString().split('T')[0] : ''
        refine(formatted)
      })
    }
  }
)
